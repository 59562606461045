.noscript-hide {
	display: none;
}

#thread-form-container {
	background: none;
	border: none;
	& > span.act {
		display: none;
	}
}

#new-thread-form, #new-reply-form {
	display: block;
	& > input[name=cancel] {
		display: none;
	}
}

.image-search{
	display: initial;
}
